import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { computed, ref } from "vue";
import { Search, Right, CircleClose } from "@element-plus/icons-vue";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SearchInput',
  props: {
    modelValue: {},
    placeholder: {}
  },
  emits: ["search", "clear", "update:modelValue"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const emit = __emit;
const isSearched = ref(false);

const keyword = computed({
  get() {
    return props.modelValue;
  },
  set(value: string) {
    emit("update:modelValue", value);
  },
});

const onInput = () => {
  isSearched.value = true;
};

const onClear = () => {
  isSearched.value = false;
  emit("update:modelValue", "");
  emit("clear");
};

const onSearch = (value: string) => {
  if (!isSearched.value) {
    return;
  }
  isSearched.value = false;
  emit("search", value);
};

return (_ctx: any,_cache: any) => {
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createBlock(_component_el_input, {
    modelValue: keyword.value,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((keyword).value = $event)),
    class: "input-search",
    placeholder: _ctx.placeholder,
    onInput: onInput,
    onClear: onClear,
    onKeydown: _cache[3] || (_cache[3] = _withKeys(() => onSearch(keyword.value), ["enter"]))
  }, {
    prefix: _withCtx(() => [
      _createVNode(BaseSvgIcon, {
        class: _normalizeClass([
          isSearched.value || keyword.value.trim().length > 0 ? 'text-green-secondary' : ''
        , "w-4 h-4 text-neutral-dark"]),
        name: "search"
      }, null, 8, ["class"])
    ]),
    suffix: _withCtx(() => [
      (isSearched.value && keyword.value.trim().length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "cursor-pointer rounded-full bg-green-secondary hover:bg-green-700 flex items-center justify-center mr-1 w-5 h-5",
            onClick: _cache[0] || (_cache[0] = () => onSearch(keyword.value))
          }, [
            _createVNode(BaseSvgIcon, {
              class: "w-4 h-4 text-white",
              name: "arrow_search"
            })
          ]))
        : _createCommentVNode("", true),
      (!isSearched.value && keyword.value.trim().length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "cursor-pointer rounded-full bg-neutral-dark hover:bg-neutral-grey flex items-center justify-center mr-1 w-5 h-5",
            onClick: _cache[1] || (_cache[1] = () => onClear())
          }, [
            _createVNode(BaseSvgIcon, {
              class: "w-3 h-3 text-white",
              name: "clear"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["modelValue", "placeholder"]))
}
}

})